import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Element } from 'react-scroll';
import Layout from '../components/layout';
import Banner from '../components/banner';
import SEO from '../components/seo';
import HeadingBlock from '../components/headingBlock';
import ExternalLink from '../components/ExternalLink';

import CitiesSEOImage from '../assets/images/banner-homepage.jpg';
import mapIcon from '../assets/images/scc-icon-lg.png';
import mapUSA from '../assets/images/map_usa.png';
import mapNE from '../assets/images/map_usa_NE.png';
import mapMW from '../assets/images/map_usa_MW.png';
import mapSO from '../assets/images/map_usa_SO.png';
import mapWE from '../assets/images/map_usa_WE.png';
import mapNEmobile from '../assets/images/map_usa_NE_mobile.png';
import mapMWmobile from '../assets/images/map_usa_MW_mobile.png';
import mapSOmobile from '../assets/images/map_usa_SO_mobile.png';
import mapWEmobile from '../assets/images/map_usa_WE_mobile.png';
import mapNEhover from '../assets/images/map_usa_NE_hover.png';
import mapMWhover from '../assets/images/map_usa_MW_hover.png';
import mapSOhover from '../assets/images/map_usa_SO_hover.png';
import mapWEhover from '../assets/images/map_usa_WE_hover.png';
import sccTitle from '../assets/images/scc-title.png';

import atlanta from '../assets/images/cities/atlanta.png';
import austin from '../assets/images/cities/austin.png';
import baltimore from '../assets/images/cities/baltimore.png';
import brooklyn from '../assets/images/cities/brooklyn.png';
import camden from '../assets/images/cities/camden.png';
import chicago from '../assets/images/cities/chicago.png';
import columbus from '../assets/images/cities/columbus.png';
import detroit from '../assets/images/cities/detroit.png';
import jackson from '../assets/images/cities/jackson.png';
import newark from '../assets/images/cities/newark.png';
import neworleans from '../assets/images/cities/neworleans.png';
import norfolk from '../assets/images/cities/norfolk.png';
import philadelphia from '../assets/images/cities/philadelphia.png';
import pittsburgh from '../assets/images/cities/pittsburgh.png';
import sanfrancisco from '../assets/images/cities/sanfrancisco.png';
import stlouis from '../assets/images/cities/stlouis.png';
import tampa from '../assets/images/cities/tampa.png';
import trenton from '../assets/images/cities/trenton.png';
import tulsa from '../assets/images/cities/tulsa.png';
import washington from '../assets/images/cities/washington.png';

class Cities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      setMidwest: 'chicago',
      setNortheast: 'baltimore',
      setSouth: 'atlanta',
      setWest: 'sanfrancisco'
    };

    this.handleMidwest = this.handleMidwest.bind(this);
    this.handleNortheast = this.handleNortheast.bind(this);
    this.handleSouth = this.handleSouth.bind(this);
    this.handleWest = this.handleWest.bind(this);
    this.handleMapButtons = this.handleMapButtons.bind(this);
    this.clearMapButtons = this.clearMapButtons.bind(this);
    this.handleMobileCities = this.handleMobileCities.bind(this);
    this.isInViewport = this.isInViewport.bind(this);
  }

  handleMidwest(e, city) {
    e.preventDefault();

    this.setState({
      setMidwest: city
    });

    this.changePanels('midwest', city);
  }

  handleNortheast(e, city) {
    e.preventDefault();

    this.setState({
      setNortheast: city
    });

    this.changePanels('northeast', city);
  }

  handleSouth(e, city) {
    e.preventDefault();

    this.setState({
      setSouth: city
    });

    this.changePanels('south', city);
  }

  handleWest(e, city) {
    e.preventDefault();

    this.setState({
      setWest: city
    });

    this.changePanels('west', city);
  }

  handleMapButtons(region) {
    var buttons = document.querySelectorAll(`.mapBtn`);
    var icons = document.querySelectorAll(`.icon`);
    var links = document.querySelectorAll('.mapNav a');
    buttons.forEach((btn) => {
      if (btn.classList.contains(region)) {
        btn.classList.add('highlight');
      } else {
        btn.classList.add('dim');
      }
    });

    // shows icon on state hovers

    // icons.forEach((icon) => {
    //   if (icon.classList.contains(region)) {
    //     icon.classList.add('show');
    //   } else {
    //     icon.classList.remove('show');
    //   }
    // });
    links.forEach((link) => {
      if (link.getAttribute('aria-label') === region) {
        link.classList.add('hover');
      } else {
        link.classList.remove('hover');
      }
    });
  }

  handleMobileCities(region) {
    var navigation = document.querySelector(`select#${region}`);
    var value = navigation.options[navigation.selectedIndex].value;

    if (region === 'northeast') {
      this.setState({
        setNortheast: value
      });
    } else if (region === 'midwest') {
      this.setState({
        setMidwest: value
      });
    } else if (region === 'south') {
      this.setState({
        setSouth: value
      });
    } else if (region === 'west') {
      this.setState({
        setWest: value
      });
    }

    this.changePanels(region, value);
  }

  clearMapButtons() {
    var buttons = document.querySelectorAll(`.mapBtn`);
    var icons = document.querySelectorAll(`.icon`);
    var links = document.querySelectorAll('.mapNav a');
    buttons.forEach((btn) => {
      btn.classList.remove('highlight');
      btn.classList.remove('dim');
    });
    icons.forEach((icon) => {
      icon.classList.remove('show');
    });
    links.forEach((link) => {
      link.classList.remove('hover');
    });
  }

  changePanels(region, city) {
    var panels = document.querySelectorAll(`.${region} .panel`);
    var buttons = document.querySelectorAll(`.cities-${region} .city`);

    panels.forEach((panel) => {
      if (panel.classList.contains(city)) {
        buttons.forEach((btn) => {
          if (btn.getAttribute('aria-label') === city) {
            btn.classList.add('active');
          } else {
            btn.classList.remove('active');
          }
        });
        panel.classList.add('active');
      } else {
        panel.classList.remove('active');
      }
    });
  }

  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  componentDidMount() {
    const block = document.querySelectorAll('#heading-block');
    document.addEventListener(
      'scroll',
      () => {
        block.forEach((item) => {
          const container = this.isInViewport(item)
            ? item.classList.add('blueColorBkgd')
            : item.classList.remove('blueColorBkgd');
          return container;
        });
      },
      { passive: true }
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query CitiesMeta {
            site {
              siteMetadata {
                title
                description
              }
            }
            allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
            ) {
              edges {
                node {
                  id
                  excerpt(pruneLength: 250)
                  frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    slug
                    title
                    featuredImage {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Layout page='cities'>
              <SEO
                title='Cities'
                description='Safer Childbirth Cities is currently funding community-based organizations in 20 U.S. cities to help improve the maternal health outcomes that matter most in their cities.'
                image={CitiesSEOImage}
                keywords={[``]}
              />
              <Banner
                title='Cities'
                subTitle={`Safer Childbirth Cities is currently funding community-based organizations in 20 U.S. cities to help improve the maternal health outcomes that matter most in their cities. Safer Childbirth Cities grantees work to engage communities in maternal health improvements and address the social determinants of health and work with doulas and perinatal support workers to bolster the care support system around women and families during pregnancy, childbirth and the months after.`}
                titleColor='#FFFFFF'
                subTitleColor='#FFFFFF'
              />
              <section id='map' className='darkGrayColorBkgd'>
                <div className='arrow-down blue'></div>
                <Container>
                  <Row>
                    <div className='spacer30'></div>
                    <Col xs='12' sm='12' md='8' lg='9'>
                      <p className='whiteColor text-center'>SELECT A REGION</p>
                      <hr className='horizontalRule centered' />
                      <div className='mapNav'>
                        <Link
                          aria-label='northeast'
                          activeClass='active'
                          to='northeast'
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                          onMouseEnter={() =>
                            this.handleMapButtons('northeast')
                          }
                          onMouseLeave={() => this.clearMapButtons()}
                        >
                          Northeast
                        </Link>
                        <Link
                          aria-label='midwest'
                          activeClass='active'
                          to='midwest'
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                          onMouseEnter={() => this.handleMapButtons('midwest')}
                          onMouseLeave={() => this.clearMapButtons()}
                        >
                          Midwest
                        </Link>
                        <Link
                          aria-label='south'
                          activeClass='active'
                          to='south'
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                          onMouseEnter={() => this.handleMapButtons('south')}
                          onMouseLeave={() => this.clearMapButtons()}
                        >
                          South
                        </Link>
                        <Link
                          aria-label='west'
                          activeClass='active'
                          to='west'
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                          onMouseEnter={() => this.handleMapButtons('west')}
                          onMouseLeave={() => this.clearMapButtons()}
                        >
                          West
                        </Link>
                      </div>
                    </Col>
                    <div className='spacer30'></div>
                    <Col xs='12' sm='12' md='8' lg='9'>
                      <Link
                        activeClass='active'
                        to='northeast'
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onMouseEnter={() => this.handleMapButtons('northeast')}
                        onMouseLeave={() => this.clearMapButtons()}
                      >
                        <img
                          className='mapBtn northeast img-responsive hidden-xs hidden-sm'
                          src={mapNE}
                          alt='Northeast'
                        />
                        <img
                          className='mapBtn northeast img-responsive hidden-xs hidden-sm hover'
                          src={mapNEhover}
                          alt='Northeast'
                        />
                        <img
                          className='mapBtn northeast img-responsive hidden-md hidden-lg'
                          src={mapNEmobile}
                          alt='Northeast'
                        />
                      </Link>
                      <Link
                        activeClass='active'
                        to='south'
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onMouseEnter={() => this.handleMapButtons('south')}
                        onMouseLeave={() => this.clearMapButtons()}
                      >
                        <img
                          className='mapBtn south img-responsive hidden-xs hidden-sm'
                          src={mapSO}
                          alt='South'
                        />
                        <img
                          className='mapBtn south img-responsive hidden-xs hidden-sm hover'
                          src={mapSOhover}
                          alt='South'
                        />
                        <img
                          className='mapBtn south img-responsive hidden-md hidden-lg'
                          src={mapSOmobile}
                          alt='South'
                        />
                      </Link>
                      <Link
                        activeClass='active'
                        to='midwest'
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onMouseEnter={() => this.handleMapButtons('midwest')}
                        onMouseLeave={() => this.clearMapButtons()}
                      >
                        <img
                          aria-label='map-button'
                          className='mapBtn midwest img-responsive hidden-xs hidden-sm'
                          src={mapMW}
                          alt='Midwest'
                        />
                        <img
                          aria-label='map-button'
                          className='mapBtn midwest img-responsive hidden-xs hidden-sm hover'
                          src={mapMWhover}
                          alt='Midwest'
                        />
                        <img
                          aria-label='map-button'
                          className='mapBtn midwest img-responsive hidden-md hidden-lg'
                          src={mapMWmobile}
                          alt='Midwest'
                        />
                      </Link>
                      <Link
                        activeClass='active'
                        to='west'
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={500}
                        onMouseEnter={() => this.handleMapButtons('west')}
                        onMouseLeave={() => this.clearMapButtons()}
                      >
                        <img
                          className='mapBtn west img-responsive hidden-xs hidden-sm'
                          src={mapWE}
                          alt='West'
                        />
                        <img
                          className='mapBtn west img-responsive hidden-xs hidden-sm hover'
                          src={mapWEhover}
                          alt='West'
                        />
                        <img
                          className='mapBtn west img-responsive hidden-md hidden-lg'
                          src={mapWEmobile}
                          alt='West'
                        />
                      </Link>
                      <img
                        className='title img-responsive'
                        src={sccTitle}
                        alt='Safer Childbirth Cities'
                      />
                      <img
                        className='mapBkgd img-responsive'
                        src={mapUSA}
                        alt='United States'
                      />

                      {/* icons to show on state hover */}

                      {/* <img
                        className='icon northeast img-responsive'
                        src={mapIcon}
                        alt='Northeast'
                      />
                      <img
                        className='icon midwest img-responsive'
                        src={mapIcon}
                        alt='Midwest'
                      />
                      <img
                        className='icon south img-responsive'
                        src={mapIcon}
                        alt='South'
                      />
                      <img
                        className='icon west img-responsive'
                        src={mapIcon}
                        alt='West'
                      /> */}
                    </Col>
                  </Row>
                </Container>
              </section>
              <Element id='anchor' name='northeast' />
              <HeadingBlock title='Northeast' light={true} />
              <section>
                <Container>
                  <div className='spacer100'></div>
                  <Row className='cities northeast'>
                    <Col
                      className='cityNav hidden-xs hidden-sm'
                      xs='3'
                      sm='3'
                      md='3'
                      lg='3'
                    >
                      <div className='cities-northeast'>
                        <button
                          aria-label='baltimore'
                          className='city active'
                          onClick={(e) => this.handleNortheast(e, 'baltimore')}
                        >
                          Baltimore, MD
                        </button>
                        <button
                          aria-label='brooklyn'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'brooklyn')}
                        >
                          Brooklyn, NY
                        </button>
                        <button
                          aria-label='camden'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'camden')}
                        >
                          Camden, NJ
                        </button>
                        <button
                          aria-label='newark'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'newark')}
                        >
                          Newark, NJ
                        </button>
                        <button
                          aria-label='philadelphia'
                          className='city'
                          onClick={(e) =>
                            this.handleNortheast(e, 'philadelphia')
                          }
                        >
                          Philadelphia, PA
                        </button>
                        <button
                          aria-label='pittsburgh'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'pittsburgh')}
                        >
                          Pittsburgh, PA
                        </button>
                        <button
                          aria-label='trenton'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'trenton')}
                        >
                          Trenton, NJ
                        </button>
                        <button
                          aria-label='washington'
                          className='city'
                          onClick={(e) => this.handleNortheast(e, 'washington')}
                        >
                          Washington, DC
                        </button>
                      </div>
                    </Col>
                    <Col
                      className='cityNav hidden-md hidden-lg'
                      xs='12'
                      sm='12'
                      md='12'
                      lg='12'
                    >
                      <select
                        id='northeast'
                        defaultValue='baltimore'
                        onChange={() => this.handleMobileCities('northeast')}
                      >
                        <option value='baltimore'>Baltimore, MD</option>
                        <option value='brooklyn'>Brooklyn, NY</option>
                        <option value='camden'>Camden, NJ</option>
                        <option value='newark'>Newark, NJ</option>
                        <option value='philadelphia'>Philadelphia, PA</option>
                        <option value='pittsburgh'>Pittsburgh, PA</option>
                        <option value='trenton'>Trenton, NJ</option>
                        <option value='washington'>Washington, DC</option>
                      </select>
                    </Col>
                    <Col
                      className='panel baltimore active'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Baltimore, MD</h2>
                        <img
                          className='img-responsive'
                          src={baltimore}
                          alt='Baltimore, MD'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://baltimorehealthystart.org'>
                            <span class='ext-link'>
                              Baltimore Healthy Start
                            </span>
                          </ExternalLink>{' '}
                          and partners are enhancing the State of Maryland’s Maternal Mortality Review process with Severe Maternal Morbidity reviews, elevating patient experiences to improve quality care, and encouraging postpartum care services to be provided alongside pediatric visits. They are also hosting events and workshops that aim to strengthen advocacy skills for families, recognize the contributions of Black maternal health leaders, and increase awareness and support to help reduce maternal and infant mortality and morbidity.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/Bmohealthystart'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/www.baltimorehealthystart.org/?fref=ts'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel brooklyn'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Brooklyn, NY</h2>
                        <img
                          className='img-responsive'
                          src={brooklyn}
                          alt='Brooklyn, NY'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.blackwomensblueprint.org/'>
                            <span class='ext-link'>
                              Black Women’s Blueprint
                            </span>
                          </ExternalLink>{' '}
                          and collaborators are using a community-driven approach to define, design, and pilot a new model of primary maternity service delivery designed to promote equity and improve outcomes for Black, Indigenous and other women of color.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/blackwomensbp?lang=en'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/blackwomens.BWBNY/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/blackwomensblueprint/?hl=en'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col className='panel camden' xs='12' sm='12' md='9' lg='9'>
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Camden, NJ</h2>
                        <img
                          className='img-responsive'
                          src={camden}
                          alt='Camden, NJ'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://camdenhealth.org/'>
                            <span class='ext-link'>
                              Camden Coalition of Healthcare Providers
                            </span>
                          </ExternalLink>{' '}
                          and its partners are strengthening citywide data infrastructures to improve connection to services and care coordination for pregnant and postpartum women. <ExternalLink href='https://camdenhealth.org/resources/pregnancy-care-initiation-pilot/'>
                            <span class='ext-link'>Through the pregnancy care initiation pilot</span>
                          </ExternalLink>{' '}, they are connecting pregnant people with prenatal and other clinical and social care earlier in their pregnancy and addressing the barriers to care for pregnant community members.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/camdenhealth'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/CamdenCoalition/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col className='panel newark' xs='12' sm='12' md='9' lg='9'>
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Newark, NJ</h2>
                        <img
                          className='img-responsive'
                          src={newark}
                          alt='Newark, NJ'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.greaternewarkhcc.org/'>
                            <span class='ext-link'>
                              Greater Newark Healthcare Coalition
                            </span>
                          </ExternalLink>{' '}
                          and its partners are educating health care providers on the best practices for maternity care, developing a public education campaign to increase women’s awareness of serious complications associated with pregnancy and the post-natal period, and disseminating recommendations to local stakeholders. <ExternalLink href='https://www.newarkmom.org/rutgers-partnership'>
                            <span class='ext-link'>In collaboration with the Rutgers School of Public Health</span>
                          </ExternalLink>{' '}, they also conducted community-based participatory research (CBPR) to understand the challenges that mothers face when seeking health care and identify key areas where health care providers could serve as better advocates for the wellbeing of Black mothers across the state.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/NewarkHealth'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/greaternewarkhcc/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/greaternewarkhcc/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel philadelphia'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Philadelphia, PA</h2>
                        <img
                          className='img-responsive'
                          src={philadelphia}
                          alt='Philadelphia, PA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p><ExternalLink href='https://www.birthjusticephilly.com'><span class='ext-link'>The Philadelphia Department of Public Health </span></ExternalLink> 
                        in partnership with&nbsp;
                          <ExternalLink href='https://healthfederation.org/'>
                            <span class='ext-link'>
                              Health Federation of Philadelphia
                            </span>
                          </ExternalLink>{' '}
                          are strengthening surveillance and reporting, improving clinical care, integrating community voices in developing solutions, addressing racial disparities in maternal health outcomes, and increasing community-based support for childbearing women through development of a maternal mortality community action team. 
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/healthfedphila'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/HealthFederationofPhiladelphia'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel pittsburgh'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Pittsburgh, PA</h2>
                        <img
                          className='img-responsive'
                          src={pittsburgh}
                          alt='Pittsburgh, PA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.jhf.org/'>
                            <span class='ext-link'>
                              Jewish Healthcare Foundation
                            </span>
                          </ExternalLink>{' '}
                          and its partners are improving coordination and
                          quality of care among local service providers,
                          educating perinatal health workers, and
                          building a local maternal health movement.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/JHForg'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/JewishHealthcareFoundation/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel trenton'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Trenton, NJ</h2>
                        <img
                          className='img-responsive'
                          src={trenton}
                          alt='Trenton, NJ'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://trentonhealthteam.org/'>
                            <span class='ext-link'>Trenton Health Team</span>
                          </ExternalLink>{' '}
                          and collaborators are creating a robust system of data analytics to expand knowledge of maternal health challenges and enhancing doula services to support women experiencing high-risk pregnancies within Black, Latinx and immigrant communities. They are also providing a system of support to improve patient access to community resources.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/TrentonHealth'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/trentonhealthteam?fref=ts'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/trentonhealth/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel washington'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Washington, DC</h2>
                        <img
                          className='img-responsive'
                          src={washington}
                          alt='Washington, DC'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.mamatotovillage.org'>
                            <span class='ext-link'>Mamatoto Village</span>
                          </ExternalLink>{' '}
                          and collaborators are strengthening maternal health care for Black women and birthing people by advocating for system and policy changes that expand comprehensive, accessible and high-quality perinatal support services and provide sustainable solutions to address homelessness and inequitable housing.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/mamatotovillage'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                         
                          <li>
                            <ExternalLink href='https://instagram.com/mamatotovillage'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                  </Row>
                  <div className='spacer100'></div>
                </Container>
              </section>
              <Element id='anchor' name='midwest' />
              <HeadingBlock title='Midwest' light={true} />
              <section>
                <Container>
                  <div className='spacer100'></div>
                  <Row className='cities midwest'>
                    <Col
                      className='cityNav hidden-xs hidden-sm'
                      xs='3'
                      sm='3'
                      md='3'
                      lg='3'
                    >
                      <div className='cities-midwest'>
                        <button
                          aria-label='chicago'
                          className='city active'
                          onClick={(e) => this.handleMidwest(e, 'chicago')}
                        >
                          Chicago, IL
                        </button>
                        <button
                          aria-label='columbus'
                          className='city'
                          onClick={(e) => this.handleMidwest(e, 'columbus')}
                        >
                          Columbus, OH
                        </button>
                        <button
                          aria-label='detroit'
                          className='city'
                          onClick={(e) => this.handleMidwest(e, 'detroit')}
                        >
                          Detroit, MI
                        </button>
                        <button
                          aria-label='stlouis'
                          className='city'
                          onClick={(e) => this.handleMidwest(e, 'stlouis')}
                        >
                          St. Louis, MO
                        </button>
                      </div>
                    </Col>
                    <Col
                      className='cityNav hidden-md hidden-lg'
                      xs='12'
                      sm='12'
                      md='12'
                      lg='12'
                    >
                      <select
                        id='midwest'
                        defaultValue='chicago'
                        onChange={() => this.handleMobileCities('midwest')}
                      >
                        <option value='chicago'>Chicago, IL</option>
                        <option value='columbus'>Columbus, OH</option>
                        <option value='detroit'>Detroit, MI</option>
                        <option value='stlouis'>St. Louis, MO</option>
                      </select>
                    </Col>
                    <Col
                      className='panel chicago active'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Chicago, IL</h2>
                        <img
                          className='img-responsive'
                          src={chicago}
                          alt='Chicago, IL'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://alliancechicago.org/'>
                            <span class='ext-link'>AllianceChicago</span>
                          </ExternalLink>
                          ,{' '}
                          <ExternalLink href='https://www.everthriveil.org/'>
                            <span class='ext-link'>Ever Thrive Illinois</span>
                          </ExternalLink>{' '}
                          and its partners are improving the quality and coordination of care to serve women at highest risk, and empowering families and social service providers through community <ExternalLink href='https://everthriveil.org/the-gathering-campaign/'>
                            <span class='ext-link'>outreach</span>
                          </ExternalLink>{' '}.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/alliancecchs'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/AllianceChicago/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel columbus'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Columbus, OH</h2>
                        <img
                          className='img-responsive'
                          src={columbus}
                          alt='Columbus, OH'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.roottrj.org/'>
                            <span class='ext-link'>ROOTT</span>
                          </ExternalLink>{' '}
                          and its partners are creating a model of community-based full-spectrum perinatal support doula care, with focus for Black women who have limited or no access to insurance, to ensure that they receive relevant, consistent, safe and equitable care.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/roottrj'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/ROOTTRJ/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/roottrj/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel detroit'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Detroit, MI</h2>
                        <img
                          className='img-responsive'
                          src={detroit}
                          alt='Detroit, MI'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                        The Southeast Michigan Perinatal Quality Improvement Coalition (SEMPQIC), the lead for Project Detroit: Voices for Life, and local collaborators like the Michigan Public Health Institute are building on existing community assets to foster conditions where Black mothers thrive by empowering Black women to advocate for their health needs and supporting care providers to reach their full potential in providing respectful and equitable care. 
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/GDAHC'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/GDAHC'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='http://instagram.com/mygdahc'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel stlouis'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>St. Louis, MO</h2>
                        <img
                          className='img-responsive'
                          src={stlouis}
                          alt='St. Louis, MO'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://jamaabirthvillage.org/'>
                            <span class='ext-link'>Jamaa Birth Village</span>
                          </ExternalLink>{' '}
                          and{' '}
                          <ExternalLink href='https://commonsensechildbirth.org/'>
                            <span class='ext-link'>Commonsense Childbirth</span>
                          </ExternalLink>{' '}
                          are increasing Black women’s access to culturally congruent holistic maternal health services by building a sustainable doula workforce and integrating doula care into existing health and hospital systems.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/jamaabirth?lang=en'>
                              <span class='ext-link'>
                                Twitter (Jamaa Birth Village)
                              </span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/jamaabirthvillage/'>
                              <span class='ext-link'>
                                Facebook (Jamaa Birth Village)
                              </span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/jamaabirthvillage/'>
                              <span class='ext-link'>
                                Instagram (Jamaa Birth Village)
                              </span>
                            </ExternalLink>
                          </li>

                          <li>
                            <ExternalLink href='https://www.facebook.com/CommonsenseChildbirthSchoolOfMidwifery/'>
                              <span class='ext-link'>
                                Facebook (Commonsense Childbirth)
                              </span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                  </Row>
                  <div className='spacer100'></div>
                </Container>
              </section>
              <Element id='anchor' name='south' />
              <HeadingBlock title='South' light={true} />
              <section>
                <Container>
                  <div className='spacer100'></div>
                  <Row className='cities south'>
                    <Col
                      className='cityNav hidden-xs hidden-sm'
                      xs='3'
                      sm='3'
                      md='3'
                      lg='3'
                    >
                      <div className='cities-south'>
                        <button
                          aria-label='atlanta'
                          className='city active'
                          onClick={(e) => this.handleSouth(e, 'atlanta')}
                        >
                          Atlanta, GA
                        </button>
                        <button
                          aria-label='austin'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'austin')}
                        >
                          Austin, TX
                        </button>
                        <button
                          aria-label='jackson'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'jackson')}
                        >
                          Jackson, MS
                        </button>
                        <button
                          aria-label='neworleans'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'neworleans')}
                        >
                          New Orleans, LA
                        </button>
                        <button
                          aria-label='norfolk'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'norfolk')}
                        >
                          Norfolk, VA
                        </button>
                        <button
                          aria-label='tampa'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'tampa')}
                        >
                          Tampa, FL
                        </button>
                        <button
                          aria-label='tulsa'
                          className='city'
                          onClick={(e) => this.handleSouth(e, 'tulsa')}
                        >
                          Tulsa, OK
                        </button>
                      </div>
                    </Col>
                    <Col
                      className='cityNav hidden-md hidden-lg'
                      xs='12'
                      sm='12'
                      md='12'
                      lg='12'
                    >
                      <select
                        id='south'
                        defaultValue='atlanta'
                        onChange={() => this.handleMobileCities('south')}
                      >
                        <option value='atlanta'>Atlanta, GA</option>
                        <option value='austin'>Austin, TX</option>
                        <option value='jackson'>Jackson, MS</option>
                        <option value='neworleans'>New Orleans, LA</option>
                        <option value='norfolk'>Norfolk, VA</option>
                        <option value='tampa'>Tampa, FL</option>
                        <option value='tulsa'>Tulsa, OK</option>
                      </select>
                    </Col>
                    <Col
                      className='panel atlanta active'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Atlanta, GA</h2>
                        <img
                          className='img-responsive'
                          src={atlanta}
                          alt='Atlanta, GA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://blackmamasmatter.org/'>
                            <span class='ext-link'>
                              Black Mamas Matter Alliance
                            </span>
                          </ExternalLink>{' '}
                          and its partners are creating a social safety-net model to link women, particularly Black women, to care and critical services that could be scaled to other communities where access to health care is limited.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/BlkMamasMatter'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/BlackMamasMatter/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/blackmamasmatter/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col className='panel austin' xs='12' sm='12' md='9' lg='9'>
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Austin, TX</h2>
                        <img
                          className='img-responsive'
                          src={austin}
                          alt='Austin, TX'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.facebook.com/MHECatx/'>
                            <span class='ext-link'>
                              The Maternal Health Equity Collaborative
                            </span>
                          </ExternalLink>{' '}
                          is offering culturally-sensitive and comprehensive perinatal childcare services to complement the wraparound community doula support of the collaborative and ensure that Black mothers and their families are able to access the holistic, whole-person care needed to alleviate maternal health complications and reduce inequities.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/atxcommunityfdn'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/austincommunityfoundation'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/atxcommunityfdn/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel jackson'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Jackson, MS</h2>
                        <img
                          className='img-responsive'
                          src={jackson}
                          alt='Jackson, MS'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://msphi.org/'>
                            <span class='ext-link'>
                              Mississippi Public Health Institute
                            </span>
                          </ExternalLink>{' '}
                          and its partners are providing community-based support to
                          pregnant and post-partum women, reducing unnecessary caesarean sections, and decreasing adverse maternal health outcomes through specially trained doulas.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/MSPublicHealthI'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/JacksonSaferChildbirthExperience/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel neworleans'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>New Orleans, LA</h2>
                        <img
                          className='img-responsive'
                          src={neworleans}
                          alt='New Orleans, LA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.iwesnola.org/'>
                            <span class='ext-link'>
                              Institute of Women and Ethnic Studies
                            </span>
                          </ExternalLink>{' '}
                          and its partners are identifying and linking birthing
                          people who have unmet health needs and are
                          disconnected from support to quality care and policy
                          solutions.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/IWES_NOLA'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/IWES.NOLA'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/iwes_nola/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col
                      className='panel norfolk'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Norfolk, VA</h2>
                        <img
                          className='img-responsive'
                          src={norfolk}
                          alt='Norfolk, VA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://urbanbabybeginnings.org/'>
                            <span class='ext-link'>Urban Baby Beginnings</span>
                          </ExternalLink>{' '}
                          and collaborators are reducing disparities for
                          childbearing women of color by building data
                          infrastructures for state and city officials,
                          strengthening community-based support systems,
                          connecting women to services provided by practitioners
                          of color and raising women’s awareness of the
                          importance of perinatal care.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/UrbanBabyBegin1/media'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/urbanbabybeginnings'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/urbanbabybeginnings/'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col className='panel tampa' xs='12' sm='12' md='9' lg='9'>
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Tampa, FL</h2>
                        <img
                          className='img-responsive'
                          src={tampa}
                          alt='Tampa, FL'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.reachupincorporated.org/'>
                            <span class='ext-link'>REACHUP, Inc.</span>
                          </ExternalLink>{' '}
                          and collaborators are improving perinatal mental
                          wellness and reducing racial and ethnic disparities in
                          perinatal health outcomes by facilitating access to a
                          holistic, inclusive and responsive continuum of care
                          for women and their families.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://www.instagram.com/reachupincorporated/?hl=en'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/REACHUP-Inc-169536686487562/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.linkedin.com/company/reachup-inc./'>
                              <span class='ext-link'>Linkedin</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col className='panel tulsa' xs='12' sm='12' md='9' lg='9'>
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>Tulsa, OK</h2>
                        <img
                          className='img-responsive'
                          src={tulsa}
                          alt='Tulsa, OK'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          The{' '}
                          <ExternalLink href='https://www.tulsabei.org/'>
                            <span class='ext-link'>
                              Tulsa Birth Equity Initiative (TBEI)
                            </span>
                          </ExternalLink>{' '}
                          equips families in Tulsa to have healthy births with
                          dignity and reduce maternal health disparities. TBEI
                          and collaborators will leverage the influence, skills
                          and experiences of local organizations to improve
                          maternal health policies, data systems and service
                          delivery systems for Black, American Indian and
                          justice-involved women and teens.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/tulsacf?lang=en'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/TulsaCF/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                  </Row>
                  <div className='spacer100'></div>
                </Container>
              </section>
              <Element id='anchor' name='west' />
              <HeadingBlock title='West' light={true} />
              <section>
                <Container>
                  <div className='spacer100'></div>
                  <Row className='cities west'>
                    <Col
                      className='cityNav hidden-xs hidden-sm'
                      xs='3'
                      sm='3'
                      md='3'
                      lg='3'
                    >
                      <div className='cities-west'>
                        <button
                          aria-label='sanfrancisco'
                          className='city active'
                          onClick={(e) => this.handleWest(e, 'sanfrancisco')}
                        >
                          San Francisco, CA
                        </button>
                      </div>
                    </Col>
                    <Col
                      className='cityNav hidden-md hidden-lg'
                      xs='12'
                      sm='12'
                      md='12'
                      lg='12'
                    >
                      <select
                        id='west'
                        defaultValue='sanfrancisco'
                        onChange={() => this.handleMobileCities('west')}
                      >
                        <option value='sanfrancisco'>San Francisco, CA</option>
                      </select>
                    </Col>
                    <Col
                      className='panel sanfrancisco active'
                      xs='12'
                      sm='12'
                      md='9'
                      lg='9'
                    >
                      <Col xs='12' sm='12' md='5' lg='5'>
                        <h2>San Francisco, CA</h2>
                        <img
                          className='img-responsive'
                          src={sanfrancisco}
                          alt='San Francisco, CA'
                        />
                      </Col>
                      <Col xs='12' sm='12' md='7' lg='7'>
                        <p>
                          <ExternalLink href='https://www.sisterweb.org'>
                            <span class='ext-link'>SisterWeb</span>
                          </ExternalLink>{' '}
                          and collaborators are increasing Black and Latinx women’s access to culturally-, racially- and ethnically-aligned doula care by providing health advocacy, wellness interventions and mental health services before, during and after birth.
                        </p>
                        <p>
                          <strong>Follow along:</strong>
                        </p>
                        <ul>
                          <li>
                            <ExternalLink href='https://twitter.com/sisterwebdoulas'>
                              <span class='ext-link'>Twitter</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.facebook.com/sisterwebdoulas/'>
                              <span class='ext-link'>Facebook</span>
                            </ExternalLink>
                          </li>
                          <li>
                            <ExternalLink href='https://www.instagram.com/sisterwebdoulas/?hl=en'>
                              <span class='ext-link'>Instagram</span>
                            </ExternalLink>
                          </li>
                        </ul>
                      </Col>
                    </Col>
                  </Row>
                  <div className='spacer100'></div>
                </Container>
              </section>
            </Layout>
          </>
        )}
      />
    );
  }
}

export default Cities;
